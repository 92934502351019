<template>
  <v-app>
    <v-container fluid>
      <v-row>
        <v-col sm="12" cols="12">
          <div
            style="
              background-color: #fff;
              padding: 20px 20px;
              border-radius: 10px;
            "
          >
            <v-col sm="12" cols="12">
              <v-row>
                <v-col sm="12" cols="12" class="py-0">
                  <h2 class="mb-0" style="font-size: 25px; font-weight: 550">
                    {{ this.$route.params.name }}
                  </h2>

                  <v-snackbar
                    :color="snackbar.color"
                    v-model="snackbar.show"
                    right
                  >
                    {{ snackbar.message }}
                  </v-snackbar>

                  <v-alert
                    border="top"
                    colored-border
                    v-show="data >= 1"
                    class="mx-2 float-right"
                    type="info"
                    elevation="2"
                  >
                    Score update is disabled due to it is approved
                  </v-alert>
                  <v-dialog v-model="dialog1" width="600px" persistent>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        v-show="data < 1"
                        v-on="on"
                        style="height: 40px"
                        class="
                          text-capitalize
                          green
                          rounded-pill
                          text-white
                          float-right
                          mr-5
                        "
                        type="primary"
                      >
                        <v-icon class="mr-2">mdi-upload</v-icon>
                        {{ $t("import_score") }}
                      </v-btn>
                    </template>
                    <v-card>
                      <v-card-title>{{ $t("import_score") }}</v-card-title>

                      <v-icon
                        class="btn_close"
                        @click="
                          dialog1 = false;
                          clear();
                        "
                        >close</v-icon
                      >
                      <v-divider />
                      <v-card-text
                        style="
                          height: 150px;
                          background-color: #edf1f5;
                          color: #333;
                        "
                      >
                        <v-container>
                          <v-form ref="form">
                            <v-row>
                              <v-col sm="12" cols="12">
                                <v-file-input
                                  show-size
                                  class="required"
                                  v-model="filecsv"
                                  accept=".csv"
                                  placeholder="Click here to select your file"
                                  label="Input File Here"
                                  type="file"
                                  :clearable="false"
                                  @change="handleFileUpload"
                                ></v-file-input>
                              </v-col>
                            </v-row>
                          </v-form>
                        </v-container>
                      </v-card-text>
                      <v-divider />
                      <v-card-actions>
                        <v-btn
                          class="btn_cancel"
                          @click="(dialog1 = false), clear()"
                          >{{ $t("cancel") }}</v-btn
                        >
                        <v-spacer></v-spacer>
                        <v-btn
                          class="green text-white"
                          :disabled="!valid"
                          @click.prevent="importScores()"
                          >{{ $t("submit") }}
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                  <v-btn
                    class="mx-4 float-right white--text rounded-pill"
                    color="blue"
                    target="_blank"
                    v-show="data < 1"
                    :href="this.csvTemplate"
                    ><v-icon left> mdi-file-document </v-icon>
                    {{ $t("download_template") }}
                  </v-btn>
                </v-col>
              </v-row>

              <div style="margin-top: 20px"></div>
              <v-breadcrumbs :items="items">
                <template v-slot:divider>
                  <v-icon>mdi-chevron-right</v-icon>
                </template>
              </v-breadcrumbs>
              <v-row justify="center">
                <v-divider class="ma-2 mt-8 mb-5" color="#e9e9e9"></v-divider>
                <v-col sm="12" cols="12">
                  <v-chip color="success" outlined class="float-left ma-2">
                    <v-icon left>mdi-content-save</v-icon>
                    AutoSave
                  </v-chip>
                  <download-excel
                    :data="listScore"
                    :fields="exportFields"
                    name="subject_scores.xls"
                  >
                    <v-btn
                      class="mx-2 float-right rounded-pill"
                      color="primary"
                      @click="filterStudent()"
                      ><h5>
                        Export Score
                        <i class="fa fa-file-excel fa-lg"></i>
                      </h5>
                    </v-btn>
                  </download-excel>
                  <template>
                    <vue-excel-editor
                      v-model="listScore"
                      @update="onSaveClose"
                      no-header-edit
                      :readonly="approve"
                      autocomplete
                      filter-row
                      enterToSouth
                      ref="grid"
                    >
                      <vue-excel-column
                        field="stu_id"
                        label="Student ID"
                        readonly
                        type="string"
                        width="200px"
                        key-field
                      />
                      <vue-excel-column
                        field="id_student"
                        label="ID"
                        readonly
                        type="string"
                        :invisible="true"
                        key-field
                      />
                      <vue-excel-column
                        field="name_en"
                        readonly
                        label="Name EN"
                        key-field
                        type="string"
                        width="300px"
                      />

                      <vue-excel-column
                        field="name_kh"
                        readonly
                        label="Name Khmer"
                        key-field
                        type="string"
                        width="300px"
                      />
                      <vue-excel-column
                        field="Attendance"
                        key-field
                        label="Attendance(Max 10)"
                        type="number"
                        width="220px"
                      />
                      <vue-excel-column
                        field="Midterm"
                        key-field
                        label="Midterm(Max 30)"
                        type="number"
                        width="220px"
                      />
                      <vue-excel-column
                        field="Final"
                        key-field
                        label="Final(Max 60)"
                        type="number"
                        width="260px"
                      />
                    </vue-excel-editor>
                  </template>
                </v-col>
              </v-row>
            </v-col>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <div>
      <div class="loading">
        <loading
          :active.sync="isLoading"
          :is-full-page="fullPage"
          :opacity="0.9"
          background-color="#dedede"
          :width="40"
          :height="40"
        >
          <div v-if="myLoading">
            <img
              width="100%"
              src="https://firebasestorage.googleapis.com/v0/b/schoolbase.appspot.com/o/images%2Floading.gif?alt=media&token=58553b10-7335-42a6-a0c8-8a6d55927816"
            />
          </div>
        </loading>
      </div>
    </div>
  </v-app>
</template>

<script>
import Papa from "papaparse";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading";
import { IMPORT_SCORE } from "@schoolbase/core";
import {
  getStudentSubjectScoreBySubjectId,
  createStudentSubjectScore,
  importScore,
} from "@schoolbase/web-client-lib";
import store from "@/store";
export default {
  name: "app",
  props: ["fullPage", "alertMessage", "color", "alertText"],
  data() {
    return {
      id: "",
      file: "",
      filecsv: [],
      snackbar: {
        show: false,
        message: null,
        color: null,
      },
      csvTemplate: IMPORT_SCORE,
      listScore: [],
      data: [],
      name_en: "",
      rowID: "",
      approve: "",
      isLoading: false,
      token: store.getters.getToken,
      obj_score: {},
      importScore: [],
      dialog1: false,
      form: false,
      valid: true,
      myLoading: false,
      stu_id: "",
      name_kh: "",
      exportFields: {},
      listStudent: [],
      items: [
        {
          text: "Session & Subject",
          disabled: false,
          href: "javascript:history.go(-1)",
        },
        {
          text: "Score",
          disabled: true,
        },
      ],
      listHeader: [
        { text: "Student ID", value: "stu_id" },
        { text: "Name EN", value: "name_en" },
        { text: "Name KH", value: "name_kh" },
        { text: "Attendance", value: "Attendance" },
        { text: "Midterm", value: "Midterm" },
        { text: "Final", value: "Final" },
        { text: "Total Score", value: "total_score" },
      ],
    };
  },

  computed: {},
  methods: {
    filterStudent() {
      if (this.listScore) {
        for (let header of this.listHeader) {
          if (this.listScore[0].hasOwnProperty(header.value)) {
            this.exportFields[header.text] = header.value;
          }
        }
      }
    },
    handleFileUpload(event) {
      this.file = event;
      this.parseFile();
    },
    parseFile() {
      Papa.parse(this.file, {
        header: true,
        skipEmptyLines: true,
        complete: function (results) {
          const importScore = results.data;
          const listStudentData = this.listScore;

          const updatedData = [];

          let i = 0,
            importedLength = importScore.length;
          while (i < importedLength) {
            const importedItem = importScore[i];
            let j = 0,
              stdLength = listStudentData.length;
            while (j < stdLength) {
              const student = listStudentData[j];
              if (student && importedItem.student_id === student.stu_id) {
                student.Attendance = importedItem.Attendance;
                student.Midterm = importedItem.Midterm;
                student.Final = importedItem.Final;
                updatedData.push(student);
              }
              j++;
            }
            i++;
          }

          this.data = updatedData;
          this.parsed = true;
        }.bind(this),
      });
    },
    async importScores() {
      try {
        this.isLoading = true;
        this.myLoading = true;
        const response = await importScore(this.data);
        window.console.log(response);
        this.snackbar = {
          message: response.message,
          color: "success",
          show: true,
        };
        this.loadScore();
      } catch (e) {
        this.snackbar = {
          message: e,
          color: "error",
          show: true,
        };
      }
      this.isLoading = false;
      this.myLoading = false;
      this.dialog1 = false;
    },
    clear() {
      this.filecsv = null;
    },
    async loadScore() {
      try {
        this.isLoading = true;
        this.myLoading = true;
        const APIResponse = await getStudentSubjectScoreBySubjectId(
          this.$route.params.id,
          this.token
        );
        this.listScore = APIResponse.payload.students;
        this.data = APIResponse.payload.approval;
        if (APIResponse.token) {
          this.$store.commit("LOGGED_TOKEN", APIResponse.token);
        }

        this.isLoading = false;
        this.myLoading = false;
      } catch (e) {
        this.isLoading = false;
        this.myLoading = false;

        // Logout user when unauthorized call
        if (e.response?.status == 401) {
          this.$store.dispatch("logOut");
          this.$router.go({
            name: "SignIn",
          });

          return;
        }

        this.snackbar = {
          message: e,
          color: "error",
          show: true,
        };
      }

      if (this.data == 0) {
        return (this.approve = false);
      } else {
        return (this.approve = true);
      }
    },

    async onSaveClose(records) {
      let data = {
        subject_id: this.$route.params.id,
        student_id: records[0].keys[1],
        values: {
          Final: records[0].keys[6],
          Midterm: records[0].keys[5],
          Attendance: records[0].keys[4],
        },
      };

      await createStudentSubjectScore(data);
      this.$forceUpdate();
    },
  },
  async mounted() {
    this.loadScore();
  },
  components: {
    Loading,
  },
};
</script>
